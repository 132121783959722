// https://developers.google.com/tag-manager/ecommerce-ga4
// https://developers.google.com/gtagjs/reference/ga4-events
export {}

const dataLayer = window.dataLayer || []
let listsDataParsed: ListData[]

declare global {
  interface Window {
    dataLayer: Record<string, any>[] // https://developers.google.com/tag-manager/devguide#datalayer
    ga4: {
      listsData: string[]
      item: Item | undefined
      initNextPageMeasurement: () => void
      pushViewItem: (items: Item[]) => void
      pushAddToCart: (items: Item[]) => void
      pushRemoveFromCart: (items: Item[]) => void
      pushBeginCheckout: (items: Item[]) => void
      findItemData: (sku: string, item_list_id: string | null) => Item
      pushPurchase: (ecommerce: Ecommerce) => void
      pushAddPaymentInfo: (ecommerce: Ecommerce) => void
      pushAddShippingInfo: (ecommerce: Ecommerce) => void
      init: () => void
    }
  }
}

type Currency = "EUR"

interface Item {
  item_id: string // item ID (context-specific)
  item_name: string // item ID (context-specific)
  quantity?: number // item quantity
  affiliation?: string // a product affiliation to designate a supplying company
  coupon?: string // coupon code used for a purchase
  discount?: number // monetary value of discount associated with a purchase
  index?: number // the index of the item in a list
  item_brand?: string // item brand
  item_category?: string // item category
  item_category2?: string // item category
  item_category3?: string // item category
  item_category4?: string // item category
  item_category5?: string // item category
  item_list_name?: string // the name of the list in which the item was presented to the user
  item_list_id?: string // the id of the list in which the item was presented to the user
  item_variant?: string // item variant
  price?: number // the monetary price of the item, in units of the specified currency parameter
  currency?: Currency // the currency, in 3-letter ISO 4217 format
}

enum Event {
  ViewItemList = "view_item_list", // item list views/impressions
  SelectItem = "select_item", // item list clicks
  ViewItem = "view_item", // view of product details
  AddToCart = "add_to_cart", // add a product to a shopping cart
  RemoveFromCart = "remove_from_cart", // remove a product
  BeginCheckout = "begin_checkout", // click on a checkout button
  Purchase = "purchase", // measure purchases
  AddPaymentInfo = "add_payment_info", // when user adds their payment during the checkout
  AddShippingInfo = "add_shipping_info", // when user adds their shipping info during the checkout
}

interface Ecommerce {
  affiliation?: string // a product affiliation to designate a supplying company
  coupon?: string // coupon code used for a purchase
  currency?: Currency // the currency, in 3-letter ISO 4217 format
  items?: Array<Item> // the items for the event
  transaction_id?: string // the unique identifier of a transaction
  shipping?: number // shipping cost associated with a transaction
  tax?: number // tax cost associated with a transaction
  value?: number // the monetary value of the event, in units of the specified currency parameter
  payment_type?: string // the chosen method of payment
  shipping_tier?: string // the shipping tier selected for delivery
}

interface ListData {
  item_list_id: string
  items: Item[]
  push_on_click_target: string
  push_on_load: boolean
}

/**
 * Push an ecommerce event to the data layer.
 * @param event https://developers.google.com/gtagjs/reference/ga4-events
 * @param ecommerce the ecommerce object
 */
function pushEcommerceEvent(event: Event, ecommerce: Ecommerce) {
  dataLayer.push({ ecommerce: null }) // clear the previous ecommerce object
  dataLayer.push({
    event: `ga4_${event}`,
    ecommerce,
  })
}

/**
 * To measure item list views/impressions, push a list of items
 * to the data layer and collect a view_item_list event along with that data.
 * @param items
 */
function pushViewItemList(items: Item[]) {
  pushEcommerceEvent(Event.ViewItemList, { items })
}

/**
 * To measure a view of product details, push a list of items
 * to the data layer and collect a view_item event along with that data.
 * @param items
 */
function pushViewItem(items: Item[]) {
  pushEcommerceEvent(Event.ViewItem, { items })
}

/**
 * Measure additions to a shopping cart.
 * @param items
 */
function pushAddToCart(items: Array<Item>) {
  pushEcommerceEvent(Event.AddToCart, { items })
}

/**
 * Measure the removal of a product from a shopping cart.
 * @param items
 */
function pushRemoveFromCart(items: Array<Item>) {
  pushEcommerceEvent(Event.RemoveFromCart, { items })
}

/**
 * Measure a checkout.
 * @param items
 */
function pushBeginCheckout(items: Array<Item>) {
  pushEcommerceEvent(Event.BeginCheckout, { items })
}

/**
 * Measure a transaction.
 * @param ecommerce
 */
function pushPurchase(ecommerce: Ecommerce) {
  pushEcommerceEvent(Event.Purchase, ecommerce)
}

/**
 * A user adds their payment info during the checkout process.
 * @param ecommerce
 */
function pushAddPaymentInfo(ecommerce: Ecommerce) {
  pushEcommerceEvent(Event.AddPaymentInfo, ecommerce)
}

/**
 * A user has submitted their shipping info.
 * @param ecommerce
 */
function pushAddShippingInfo(ecommerce: Ecommerce) {
  pushEcommerceEvent(Event.AddShippingInfo, ecommerce)
}

/**
 * Initialise measurement of product/item list views/impressions.
 */
function initViewItemList(data: ListData[]) {
  data.forEach(({ push_on_load, push_on_click_target, items }) => {
    // push the event immediately
    if (push_on_load) {
      pushViewItemList(items)
    }

    // push the event only after a click on an element
    if (push_on_click_target) {
      const link = document.querySelector(`[href='${push_on_click_target}']`)
      link.addEventListener("click", () => pushViewItemList(items))
    }
  })
}

/**
 * Initialise measurement of product/item list clicks.
 */
function initSelectItem(data: ListData[]) {
  for (const el of Array.from(
    document.querySelectorAll("[data-ga4-select-item]:not([data-ga4-listener])")
  )) {
    const item = findItemData(
      el.getAttribute("data-sku"),
      el
        .closest("[data-ga4-item-list-id]")
        ?.getAttribute("data-ga4-item-list-id"),
      data
    )

    if (item != null) {
      el.setAttribute("data-ga4-listener", "true")
      el.addEventListener("click", () => {
        pushEcommerceEvent(Event.SelectItem, { items: [item] })
      })
    }
  }
}

/**
 * Finds the item data by its SKU and optionally by its item_list_id.
 * @param sku The item SKU.
 * @param item_list_id GA4 item list id.
 * @param data Array to search through. All the lists' data will be searched if not provided.
 */
function findItemData(
  sku: string,
  item_list_id?: string,
  data?: ListData[]
): Item | null {
  for (const list of data ?? listsDataParsed) {
    if (
      item_list_id == null ||
      item_list_id == list.item_list_id // don't search through lists that have different id
    ) {
      for (const item of list.items) {
        if (item.item_id == sku) {
          return item
        }
      }
    }
  }

  console.error("GA4 item data not found.")
  return null
}

/**
 * Initialise measurement of items added to the list by AJAX.
 */
function initNextPageMeasurement() {
  const listData: ListData = JSON.parse(
    window.ga4.listsData[window.ga4.listsData.length - 1]
  )
  listsDataParsed.push(listData)
  initViewItemList([listData])
  initSelectItem([listData])
}

function init() {
  listsDataParsed = window.ga4.listsData.map((list) => JSON.parse(list))
  initViewItemList(listsDataParsed)
  initSelectItem(listsDataParsed)
}

window.ga4 = {
  ...window.ga4,
  initNextPageMeasurement,
  pushViewItem,
  pushAddToCart,
  pushRemoveFromCart,
  pushBeginCheckout,
  pushPurchase,
  findItemData,
  pushAddPaymentInfo,
  pushAddShippingInfo,
  init,
}
